document.addEventListener('DOMContentLoaded', () => {
    const supportMobile = document.querySelector('.side-menu-mobile .support'),
        loginMobile = document.querySelector('.side-menu-mobile .login'),
        supportMobileTrigger = document.querySelector('.side-menu-mobile .support .trigger'),
        loginMobileTrigger = document.querySelector('.side-menu-mobile .login .trigger'),
        contactMobileTrigger = document.querySelector('.side-menu-mobile .contact .trigger')

    var supportClick = false,
        loginClick = false

    supportMobileTrigger.addEventListener('click', (e) => {
        e.preventDefault()

        if (!supportClick) {
            supportMobile.style.transform = 'translateY(-200px)'
            document.querySelector('.side-menu-mobile .support').style.zIndex = '10'
            supportMobileTrigger.querySelector('img').style.display = 'none'
            supportMobileTrigger.querySelector('span').style.display = 'none'
            supportMobileTrigger.querySelector('.close').style.display = 'block'
            supportClick = true
        } else {
            supportMobile.style.transform = 'translateY(0)'
            document.querySelector('.side-menu-mobile .support').style.zIndex = '0'
            supportMobileTrigger.querySelector('img').style.display = 'block'
            supportMobileTrigger.querySelector('span').style.display = 'block'
            supportMobileTrigger.querySelector('.close').style.display = 'none'
            supportClick = false
        }
    })


    loginMobileTrigger.addEventListener('click', (e) => {
        e.preventDefault()

        window.location = 'https://wispbilling.com/'

        // if (!loginClick) {
        //     loginMobile.style.transform = 'translateY(-200px)'
        //     document.querySelector('.side-menu-mobile .login').style.zIndex = '10'
        //     loginMobileTrigger.querySelector('img').style.display = 'none'
        //     loginMobileTrigger.querySelector('span').style.display = 'none'
        //     loginMobileTrigger.querySelector('.close').style.display = 'block'
        //     loginClick = true
        // } else {
        //     loginMobile.style.transform = 'translateY(0)'
        //     document.querySelector('.side-menu-mobile .login').style.zIndex = '1'
        //     loginMobileTrigger.querySelector('img').style.display = 'block'
        //     loginMobileTrigger.querySelector('span').style.display = 'block'
        //     loginMobileTrigger.querySelector('.close').style.display = 'none'
        //     loginClick = false
        // }
    })


})