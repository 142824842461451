import swiper from 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.min'

document.addEventListener('DOMContentLoaded', () => {

    const testimonials_slider = new swiper('.testimonials-slider', {
        direction: 'horizontal',
        loop: true,
        autoplay: {
            delay: 10000,
        },
        centeredSlides: true,
        navigation: {
            nextEl: '.slide-next',
            prevEl: '.slide-prev'
        }
    })

})