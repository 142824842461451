import $ from 'jquery'
import {
    Scene,
    Controller
} from 'scrollmagic'
import 'scrollmagic/scrollmagic/minified/plugins/animation.gsap.min'


var sectionTitles = document.querySelectorAll('.section-title'),
    parallax = document.querySelectorAll('.parallax-parent'),
    parallaxAlt = document.querySelectorAll('.parallax-alt-parent'),
    parallaxUp = document.querySelectorAll('.parallax-up-parent'),
    staggerParent = document.querySelectorAll('.stagger-parent'),
    staggerLeftParent = document.querySelectorAll('.stagger-left-parent'),
    fadeUpParent = document.querySelectorAll('.fade-up-parent'),
    fadeUpParentOnLoad = document.querySelectorAll('.fade-up-parent-onload'),
    controller = new Controller({ refreshInterval: 200 })

sectionTitles.forEach(obj => {
  // build scene
    var scene = new Scene({
        triggerElement: obj,
        triggerHook: 'onEnter',
    })
    // trigger animation by adding a css class
    .setClassToggle(obj, "anim-heading-start")
    .addTo(controller)  
})


parallax.forEach(obj => {
    new Scene({
        triggerElement: obj,
        duration: '200%',
        triggerHook: 'onLeave'
    })
    .setTween('.parallax-parent .parallax-item', {y: "-40%", ease: Linear.easeNone})
    .addTo(controller)
})

parallaxAlt.forEach(obj => {
    new Scene({
        triggerElement: obj,
        duration: '200%',
        triggerHook: 'onCenter',
    })
    .setTween('.parallax-alt-parent .parallax-alt-item', {y: "-30%", ease: Linear.easeNone})
    .addTo(controller)
})

parallaxUp.forEach(obj => {
    new Scene({
        triggerElement: obj,
        duration: '200%',
        triggerHook: 'onCenter',
    })
    .setTween('.parallax-up-parent .parallax-up-item', {y: "-30%", ease: Linear.easeNone})
    .addTo(controller)
})

staggerParent.forEach(obj => {
    var tween = new TimelineMax()
        .staggerTo(".stagger-parent .stagger-item", 1, { opacity: 1, y: 0, ease: Linear.ease }, 0.8)
    
    var scene = new Scene({
        triggerElement: obj,
        duration: '0',
        triggerHook: 'onCenter',
        offset: 0
    })
    .setTween(tween)
        .addTo(controller)

       
})

staggerLeftParent.forEach(obj => {
    var tween = new TimelineMax()
        .staggerTo(".stagger-left-parent .stagger-left-item", 1, { opacity: 1, x: 0, ease: Power1.out }, 0.8)

    var scene = new Scene({
        triggerElement: obj,
        triggerHook: 'onCenter',
        offset: -100,
    })
    .setTween(tween)
    .addTo(controller)

})


fadeUpParent.forEach(obj => {
    var scene = new Scene({
        triggerElement: obj,
        duration: '0',
        triggerHook: 'onCenter',
        offset: 0
    })
    .setTween(new TimelineMax()
        .to(".fade-up-parent .fade-up-item", 1, {opacity: 1, y: 0, stagger: 0.5, ease: Linear.ease}))
        .addTo(controller)
    
})

fadeUpParentOnLoad.forEach(obj => {
    var scene = new Scene({
        triggerElement: obj,
        duration: '0',
        triggerHook: '1',
        offset: 0
    })
    .setTween(new TimelineMax()
        .to(".fade-up-parent-onload .fade-up-item", 1, {opacity: 1, y: 0, stagger: 0.5, ease: Linear.ease}))
        .addTo(controller)
})


function getMilliSeconds(num) {
    return (num % 1) * 1000;
}
