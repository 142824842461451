import swiper from 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.min'

document.addEventListener('DOMContentLoaded', () => {

    const clients_slider = new swiper('.clients-slider', {
        direction: 'horizontal',
        loop: true,
        autoplay: {
            delay: 2000,
        },
        watchOverflow: true,
        slidesPerView: 3,
        loopedSlides: 3,
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 10
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 1,
                spaceBetween: 10
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 1,
                spaceBetween: 0
            }
        },

        navigation: {
            nextEl: '.slide-next',
            prevEl: '.slide-prev'
        }
    })

    clients_slider.init()

})