import swiper from 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.min'

document.addEventListener('DOMContentLoaded', () => {
    var prev = document.querySelectorAll('.navigation .prev'),
        next = document.querySelectorAll('.navigation .next')

    var our_clients_content = new swiper('.our-clients-content', {
        direction: 'horizontal',
        loop: true,
        autoplay: false,
        centeredSlides: true,

        navigation: {
            nextEl: '.navigation .next',
            prevEl: '.navigation .prev'
        }
    })

    var our_clients_image = new swiper('.our-clients-image', {
        direction: 'horizontal',
        loop: true,
        autoplay: false,
        centeredSlides: true,
        effect: 'cube',
        cubeEffect: {
            shadow: false,
            slideShadows: false,
            shadowOffset: 20,
            shadowScale: 0.94,
        },
    })

    our_clients_content.on('slideChangeTransitionEnd', () => {
        our_clients_image.slideToLoop(our_clients_content.realIndex)
    })

})