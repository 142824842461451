// import main SASS file
import '../../styles/scss/style.scss'

// Import Foundation
import './init-foundation'


// Load JS modules to import at runtime
import './modules/globals'
import './modules/lazyload'
import './modules/ie'
import './modules/sideMenu'
import './modules/sideMenuMobile'
import './modules/clients'
import './modules/ourClients'
import './modules/testimonials'
import './modules/animations'
import './modules/news'
import './modules/support'


// Mac Veneer font spacing hack
if (navigator.userAgent.match(/Macintosh/) || navigator.userAgent.match(/(iPhone|iPod|iPad)/i)) {
    document.querySelectorAll('.heading-1, .heading-2, .heading-3, .btn-default, .btn, .section-title').forEach((obj, index) => {
        obj.classList.add('is-mac')
    })
}

var viewport = window.outerWidth || document.documentElement.clientWidth || document.body.clientWidth;

document.querySelector('.menu-item-23 a').addEventListener('click', (e) => {
    e.preventDefault()
})

var menuItemHasChildren = document.querySelectorAll('.menu-item-has-children')
if (viewport < 1024) { 
    menuItemHasChildren.forEach((obj, index) => {
        obj.addEventListener('click', (e) => { 
            e.preventDefault();
            obj.classList.toggle('submenuIsActive');
            obj.querySelector('.sub-menu').querySelectorAll('a').forEach((anchor, index) => { 
                anchor.addEventListener('click', (e) => {
                    window.location = anchor.getAttribute('href')
                })
            })
        })
    });
}

