const isSupport = typeof (document.querySelector('.support-page')) != 'undefined' && document.querySelector('.support-page') != null

if (isSupport) {
    var faqControl = document.getElementById('faq'),
        troubleshootingControl = document.getElementById('troubleshooting'),
        faqContent = document.querySelector('.tab-faq'),
        troubleshootingContent = document.querySelector('.tab-troubleshooting'),
        tabItems = document.querySelectorAll('.tab-item-container'),
        tabs = document.getElementById('tabs'),
        faq = document.getElementById('faq'),
        troubleshooting = document.getElementById('troubleshooting')

    // faqControl.addEventListener('click', (e) => {
    //     e.preventDefault()
    //     troubleshootingControl.classList.remove('tab-active')
    //     faqControl.classList.add('tab-active')
    //     troubleshootingContent.classList.remove('tab-content-active')
    //     faqContent.classList.add('tab-content-active')
    // })

    // troubleshootingControl.addEventListener('click', (e) => {
    //     e.preventDefault()
    //     faqControl.classList.remove('tab-active')
    //     troubleshootingControl.classList.add('tab-active')
    //     faqContent.classList.remove('tab-content-active')
    //     troubleshootingContent.classList.add('tab-content-active')
    // })

    tabItems.forEach((obj, index) => {
        obj.addEventListener('click', (e) => {
            e.preventDefault()
            e.stopPropagation()

            if (obj.classList.contains('tab-item-open')) {
                obj.classList.remove('tab-item-open')
            } else {
                obj.classList.add('tab-item-open')
            }
        })
    })
}


if (window.location.href.indexOf('?faq') != -1) {
    // faq.click()
    setTimeout(() => {
        tabs.scrollIntoView(true)
    }, 500)
}

if (window.location.href.indexOf('?troubleshooting') != -1) {
    // troubleshooting.click()
    setTimeout(() => {
        tabs.scrollIntoView(true)
    }, 500)
}